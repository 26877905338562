import HeaderNavSlideDown from '@/components/@general/Header/HeaderNav/HeaderNavSlidedown/index.vue';
import IconArrowDown from '@/components/icons/IconArrowDown.vue';
import { VueSlideToggle } from 'vue-slide-toggle';

export default {
  name: 'HeaderNavSlideDown',
  components: {
    IconArrowDown,
    HeaderNavSlideDown,
    VueSlideToggle
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    isHasItems() {
      return this.data.items && this.data.items.length;
    }
  },
  methods: {
    onClick() {
      this.isOpen = !this.isOpen;
    }
  }
};
