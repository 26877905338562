import { $http } from '@/utils/https';
import type { ActionContext } from 'vuex';
import type { RootState } from '@/store';
import type { CooperationTerm, InnovativeIngredient, NewContent, News, Page, PageContent } from '@/types/site';
import type { Faq, PaginatorData } from '@/types/api';
import type { WebinarCourseTheme } from '@/types/webinars';
import type { IUserRequestPayload } from '@/store/modules/types/pages';

type ContentSection = PageContent | NewContent;

type RowContentSection = {
  id: number;
  type: string;
  items: Array<ContentSection>;
};

type PageSectionBlocks = Array<RowContentSection | ContentSection>;
type PreparePageContent = (page: News | Page) => PageSectionBlocks;

const preparePageContent: PreparePageContent = (page) => {
  let row: Array<ContentSection> = [];
  let result: PageSectionBlocks = [];

  let rowBlockId = 0;
  for (const pageContent of page.pageContents?.data || []) {
    if (pageContent.type === 'column_block') {
      row.push(pageContent);
      continue;
    }
    if (row.length) {
      result.push({
        id: --rowBlockId,
        type: 'row_with_block',
        items: Array.from(row)
      });
      row = [];
    }
    result.push(pageContent);
  }
  return result;
};

interface State {
  page: Page | News | CooperationTerm | null;
  pagesLoading: boolean;
  requestLoading: boolean;
  pageSectionBlocks: PageSectionBlocks;
}

const state: State = {
  page: null,
  pagesLoading: false,
  requestLoading: false,
  pageSectionBlocks: []
};

const getters = {
  pageSectionBlocks: (state: State) => state.pageSectionBlocks,
  page: (state: State) => state.page,
  pagesLoading: (state: State) => state.pagesLoading,
  requestLoading: (state: State) => state.requestLoading
};

const actions = {
  USER_REQUESTS: async ({ commit }: ActionContext<State, RootState>, payload: IUserRequestPayload) => {
    commit('CHANGE_REQUEST_LOADING', true);
    try {
      await $http.post(`v1/user_requests`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_REQUEST_LOADING', false);
    }
  },
  GET_ONE_TERM: async ({ commit }: ActionContext<State, RootState>, slug: string) => {
    commit('CHANGE_PAGE_LOADING', true);
    try {
      const response = await $http.get<{ data: CooperationTerm }>(`v1/cooperation_terms/${slug}`);
      commit('SET_PAGE', response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_PAGE_LOADING', false);
    }
  },
  COOPERATION_TERMS: async ({ commit }: ActionContext<State, RootState>) => {
    commit('CHANGE_PAGE_LOADING', true);
    try {
      const response = await $http.get<{ data: Array<CooperationTerm> }>(`v1/cooperation_terms`);

      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_PAGE_LOADING', false);
    }
  },
  WEBINAR_COURSE_THEMES: async ({ commit }: ActionContext<State, RootState>) => {
    commit('CHANGE_PAGE_LOADING', true);
    try {
      const response = await $http.get<{ data: Array<WebinarCourseTheme> }>(`v1/webinars/themes`);

      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_PAGE_LOADING', false);
    }
  },
  GET_NEWS_PAGE: async ({ commit }: ActionContext<State, RootState>, slug: string) => {
    commit('CHANGE_PAGE_LOADING', true);
    try {
      const response = await $http.get<{ data: News }>(`v1/news/${slug}`);
      const newsPage = response.data.data;

      commit('SET_PAGE', newsPage);
      commit('SET_PAGE_SECTION_BLOCKS', preparePageContent(newsPage));
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_PAGE_LOADING', false);
    }
  },
  GET_NEWS: async ({ commit }: ActionContext<State, RootState>, paginatorInfo: { page: number; perPage: number }) => {
    commit('CHANGE_PAGE_LOADING', true);
    try {
      const response = await $http.get<PaginatorData<News[]>>(`v1/news`, {
        params: paginatorInfo
      });

      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_PAGE_LOADING', false);
    }
  },
  GET_WEBINAR_THEME: async ({ commit }: ActionContext<State, RootState>, payload: string) => {
    commit('CHANGE_PAGE_LOADING', true);
    try {
      const response = await $http.get<{ data: WebinarCourseTheme }>(`v1/webinars/themes/${payload}`);

      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_PAGE_LOADING', false);
    }
  },
  GET_PAGE: async ({ commit }: ActionContext<State, RootState>, slug: string) => {
    commit('CHANGE_PAGE_LOADING', true);
    try {
      const response = await $http.get<{ data: Page }>(`v1/pages/${slug}`);
      const page = response.data.data;

      commit('SET_PAGE', page);
      commit('SET_PAGE_SECTION_BLOCKS', preparePageContent(page));
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_PAGE_LOADING', false);
    }
  },
  GET_FAQ: async ({ commit }: ActionContext<State, RootState>) => {
    commit('CHANGE_PAGE_LOADING', true);
    try {
      const response = await $http.get<{ data: Array<Faq> }>(`v1/faqs`);

      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_PAGE_LOADING', false);
    }
  },

  // eslint-disable-next-line no-unused-vars
  GET_CONTACTS: async ({ commit }: any) => {
    try {
      const response = await $http.get(`v1/contact-page`);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  GET_ABOUT_US: async ({ commit }: any) => {
    try {
      const response = await $http.get(`v1/about-us-page`);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  SET_PAGE_SECTION_BLOCKS(state: State, data: PageSectionBlocks) {
    state.pageSectionBlocks = data;
  },
  SET_PAGE(state: State, obj: Page | News | CooperationTerm) {
    state.page = obj;
  },
  CHANGE_REQUEST_LOADING(state: State, status: boolean) {
    state.requestLoading = status;
  },
  CHANGE_PAGE_LOADING(state: State, status: boolean) {
    // setTimeout(()=>{
    state.pagesLoading = status;
    // },1000)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
