import { $http } from '@/utils/https';
import { objectToQueryString } from '@/helpers/variables';
import type { ICatalogProductsParams } from '@/store/modules/types/products';

interface State {}

const state: State = {};

const getters = {};

const actions = {
  GET_CATALOG_CATEGORIES: async () => {
    let url = `v1/catalog/categories`;
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  GET_CATALOG_PRODUCTS: async ({ commit }: any, params: ICatalogProductsParams) => {
    let url = `v1/catalog/products`;
    if (params && Object.keys(params).length) {
      url = objectToQueryString(url, { per_page: 12, ...params });
    }

    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  GET_CATALOG_CATEGORY: async ({ commit }: any, slug: any) => {
    let url = `v1/catalog/category/${slug}`;

    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
