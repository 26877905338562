import { $http } from '@/utils/https';
import { objectToQueryString } from '@/helpers/variables';

interface State {
  archives: Array<any>
}

const state: State = {
  archives: []
};

const getters = {
  archives: (state: State) => state.archives,
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  GET_BLOG_NEWS: async ({ commit }: any, params: any) => {
    let url = `v1/news`;
    if (params && Object.keys(params).length) {
      url = objectToQueryString(url, { ...params, per_page: 12 });
    }
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  GET_BLOG_INTERESTING_ARTICLES: async ({ commit }: any, params: any) => {
    let url = `v1/interesting-articles`;
    if (params && Object.keys(params).length) {
      url = objectToQueryString(url, { ...params, per_page: 12 });
    }
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  GET_BLOG_NEWS_ARTICLE: async ({ commit }: any, slug: any) => {
    let url = `v1/news/${slug}`;
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  GET_BLOG_INTERESTING_ARTICLE: async ({ commit }: any, slug: any) => {
    let url = `v1/interesting-articles/${slug}`;
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  GET_BLOG_VIDEOS: async ({ commit }: any, params: any) => {
    let url = `v1/videos`;
    if (params && Object.keys(params).length) {
      url = objectToQueryString(url, { ...params, per_page: 12 });
    }
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  GET_BLOG_VIDEO: async ({ commit }: any, slug: any) => {
    let url = `v1/videos/${slug}`;
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  GET_BLOG_MASTER_CLASSES: async ({ commit }: any, params: any) => {
    let url = `v1/video-master-klassy`;
    if (params && Object.keys(params).length) {
      url = objectToQueryString(url, { ...params, per_page: 12 });
    }
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  GET_BLOG_MASTER_CLASS: async ({ commit }: any, slug: any) => {
    let url = `v1/video-master-klassy/${slug}`;
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },

  GET_BLOG_FRESH: async () => {
    let url = `v1/news/fresh`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },

  GET_BLOG_CATEGORIES: async () => {
    let url = `v1/news/categories`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },

  GET_BLOG_ARCHIVES: async ({ commit }: any) => {
    let url = `v1/news/archives`;
    try {
      const response = await $http.get(url);
      let archives = response.data.data
      commit('SET_BLOG_ARCHIVES', archives)
      return archives;
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  GET_BLOG_ARCHIVE: async ({ commit }: any, params: any) => {
    let url = `v1/news/by-date`;
    if (params && Object.keys(params).length) {
      url = objectToQueryString(url, { ...params, per_page: 12 });
    }
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  SET_BLOG_ARCHIVES(state: State, archives: Array<any>) {
    state.archives = archives;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
