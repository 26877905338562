import { $http } from '@/utils/https';
import type { ActionContext } from 'vuex';
import type { RootState } from '@/store';
import type { SearchResult } from '@/types/api';

interface State {}

const state: State = {};

const getters = {};

const actions = {
  GET_SEARCH_LIST: async (state: any, query: string | null) => {
    let url = `v1/search`;
    try {
      const response = await $http.get<{ data: SearchResult }>(url, { params: { query } });

      return response.data.data;
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
