import {$http} from '@/utils/https'
import type {ActionContext} from "vuex";
import type {RootState} from "@/store";
import type {IProductWhenAvailablePayload} from "@/store/modules/types/products";
import type {User} from "@/types/user";

interface State {
  showNotifyLoader: boolean
}

const state: State = {
  showNotifyLoader: false,
}

const getters = {
  showNotifyLoader: (state: State) => state.showNotifyLoader,
}

const actions = {
  SHOW_POPUP_PRODUCT_WHEN_AVAILABLE: async (context: ActionContext<State, RootState>, payload: IProductWhenAvailablePayload) => {
    const currentUser: User|null = context.rootGetters['profile/user'];
    // if no city - show popup
    if (!currentUser || !currentUser.city) {
      context.commit('popups/SHOW_NOTIFY_WHEN_AVAILABLE', {status:true, notifyPayload: payload}, {root: true})
      return;
    }

    const showThanksPopup = () => {
      context.commit('popups/SHOW_SEMINAR_DETAILS_POPUP', {
        status: true,
        data: {
          title: "",
          detail: "Дякуємо за звернення! Щойно продукт з'явиться в наявності, ми обов'язково Вам повідомимо",
        },
      }, {root: true});
    };

    try {
      await context.dispatch('SET_PRODUCT_WHEN_AVAILABLE', payload)
      showThanksPopup()
    } catch (e) {
      showThanksPopup()
    }
  },
  SET_PRODUCT_WHEN_AVAILABLE: async ({commit}: ActionContext<State, RootState>, payload: IProductWhenAvailablePayload) => {
    commit('SHOW_NOTIFY_LOADER', true);
    try {
      await $http.post<{ message: string }>('v1/products/notify-when-available', payload);
    } catch (e) {
      throw e;
    } finally {
      commit('SHOW_NOTIFY_LOADER', false);
    }
  },
}

const mutations = {
  SHOW_NOTIFY_LOADER(state: State, status: boolean) {
    state.showNotifyLoader = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
