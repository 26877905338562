import { mapGetters } from 'vuex';
import { MEDIA } from '@/helpers/variables';
import HeaderNavDropdown from '@/components/@general/Header/HeaderNav/HeaderNavDropdown/index.vue';
import HeaderNavSlidedown from '@/components/@general/Header/HeaderNav/HeaderNavSlidedown/index.vue';

export default {
  name: 'HeaderNav',

  components: {
    HeaderNavDropdown,
    HeaderNavSlidedown
  },

  data() {
    return {
      MEDIA,
      navData: [
        {
          title: 'nav.about',
          route: { name: 'about-us' },
          id: 'about'
        },
        {
          title: 'nav.catalog',
          route: { name: 'products' },
          id: 'products',
          items: [
            {
              title: 'nav.catalogNew',
              route: { name: 'products-newest' },
              id: 'newest'
            },
            {
              title: 'nav.catalogSeries',
              id: 'series'
            },
            {
              title: 'nav.catalogBestsellers',
              route: { name: 'products-bestsellers' },
              id: 'bestsellers'
            },
            {
              title: 'nav.catalogIngredients',
              route: { name: 'ingredients' },
              id: 'ingredients'
            }
          ]
        },
        {
          title: 'nav.selection',
          route: { name: 'selection' },
          id: 'selection'
        },
        {
          title: 'nav.info',
          id: 'info',
          items: [
            {
              title: 'nav.infoNews',
              route: { name: 'news' },
              id: 'news'
            },
            {
              title: 'nav.infoInteresting',
              route: { name: 'interesting' },
              id: 'interesting'
            },
            {
              title: 'nav.infoVideo',
              route: { name: 'videos' },
              id: 'videos'
            }
          ]
        },
        {
          title: 'nav.study',
          id: 'study',
          items: [
            {
              title: 'nav.studyVideo',
              route: { name: 'master-classes' },
              id: 'master_classes'
            }
          ]
        }
      ]
    };
  },
  created() {},
  mounted() {},
  watch: {
    headerMenu: {
      immediate: true,
      handler(newVal) {
        if (newVal.length) {
          let data = this._preapareData(newVal);
          this._updateItemNavById(this.navData, 'series', data);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      appSize: 'app/size',
      headerMenu: 'setting/headerMenu'
    })
  },
  methods: {
    _preapareData(data) {
      return data.map((item) => {
        return {
          title: item.title,
          route: { name: 'series', params: { series: item.slug } },
          id: item.slug.replace(/-/g, '_')
        };
      });
    },
    _updateItemNavById(navData, targetId, data) {
      const findAndModify = (items) => {
        for (const item of items) {
          if (item.id === targetId) {
            item['items'] = data;
            return true;
          }

          if (item.items && Array.isArray(item.items)) {
            if (findAndModify(item.items)) {
              return true;
            }
          }
        }
        return false;
      };
      findAndModify(navData);
      this.navData = { ...navData };
    }
  }
};
