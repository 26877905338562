




































import { mapActions, mapGetters, mapMutations } from 'vuex';
import Hidden from '@/components/@general/Hidden/index.vue';
import Header from '@/components/@general/Header/index.vue';
import Footer from '@/components/@general/Footer/index.vue';
import { isIPad, isMobile } from '@/helpers/variables';

import { defineComponent } from 'vue';
import IconScrollTop from '@/components/icons/IconScrollTop.vue';
import MainLoader from '@/components/@general/MainLoader/index.vue';

export default defineComponent({
  name: 'app',
  data() {
    return {};
  },
  components: {
    MainLoader,
    IconScrollTop,
    Hidden,
    Header,
    Footer
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`
    })
  },
  created() {
    if (this.isAuthenticated) {
      this.fetchUser();
      this.getFavorites();
    }
    this.getVariables();
  },
  mounted() {
    if (!isMobile.any()) {
      document.querySelector('body')?.classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body')?.classList.add('iPad');
    }
    window.onresize = () => {
      this.setResize({ w: window.innerWidth, h: window.innerHeight });
    };
  },
  methods: {
    ...mapMutations({
      setResize: 'app/SET_RESIZE'
    }),
    ...mapActions({
      fetchUser: 'profile/FETCH_USER_DATA',
      getFavorites: 'favorites/GET_FAVORITES_LIST',
      getVariables: 'setting/GET_VARIABLES'
    }),
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
});
