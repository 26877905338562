import store from '@/store';
import { isEmpty } from 'lodash';
import queryString from 'querystring';

export function globalLoader(state) {
  store.commit(`system/SHOW_GLOBAL_LOADER`, state);
}

export function fixBody(state) {
  store.commit(`system/FIX_BODY`, state);
}

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i) ? true : false;
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i) ? true : false;
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) ? true : false;
  },
  any: function () {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows();
  }
};
export const isIPad = {
  iOS: function () {
    return navigator.userAgent.match(/iPad|iPod/i) ? true : false;
  }
};

export const objectToString = (params) => queryString.stringify(params);

export const objectToQueryString = (sourceUrl, queryParams) => {
  let formattedUrl = sourceUrl;
  if (!isEmpty(queryParams)) {
    formattedUrl += '?';
    formattedUrl += objectToString(queryParams);
  }

  return formattedUrl;
};

export const pluralizate = (value, wordOne, wordSimple, wordMany) => {
  let language = store.getters.currentLanguage.translationKey;
  let localization = language && language.length > 2 ? language.slice(0, 2) : language;
  let pluralWord = '';

  if (localization === 'en') {
    pluralWord = value === 1 ? wordOne : wordSimple;
  } else if (localization === 'ru' || localization === 'ua') {
    pluralWord =
      value % 10 === 1 && value % 100 !== 11
        ? wordOne
        : value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)
          ? wordSimple
          : wordMany;
  }
  return pluralWord;
};

export const MEDIA = {
  M1920: 1920,
  M1680: 1680,
  M1440: 1440,
  M1360: 1360,
  M1280: 1280,
  M1140: 1140,
  M1024: 1024,
  M768: 768,
  M640: 640,
  M480: 480,
  M425: 425
};
