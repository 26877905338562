interface State {
  globalLoader: any;
  fixBody: any;
  popup: any;
}

const state: State = {
  globalLoader: true,
  fixBody: false,
  popup: {
    title: '',
    text: ''
  }
};

const getters = {
  globalLoader: (state: State) => state.globalLoader,
  fixBody: (state: State) => state.fixBody,
  popup: (state: State) => state.popup
};

const mutations = {
  SHOW_GLOBAL_LOADER(state: State, status: any) {
    state.globalLoader = status;
  },
  FIX_BODY(state: State, param: any) {
    const body = document.querySelector('body');
    if (!body) return;
    if (param) {
      body.classList.add('fix');
    } else {
      body.classList.remove('fix');
    }
    state.fixBody = param;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
