import { $http } from '@/utils/https';

interface State {
  metaData: any;
}

const state: State = {
  metaData: null
};

const getters = {
  metaData: (state: State) => state.metaData
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  GET_META_DATA: async ({ commit }: any, slug: string) => {
    let url = `v1/seo_content/${slug}`;
    try {
      const response = await $http.get(url);
      commit('SET_META_DATA', response.data.data);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {
  SET_META_DATA(state: State, metaData: any) {
    state.metaData = metaData;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
