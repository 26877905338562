import {mapActions, mapGetters, mapMutations} from "vuex";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
    name: 'CreateManagerPopup',
    data() {
        return {
            payload: {
                first_name: '',
                last_name: '',
                email: '',
                password: ''
            },
            validationErrors: {},
        }
    },
    mixins: [validation],
    validations: {
        payload: {
            first_name: {
                required,
                maxLength: maxLength(255),
            },
            last_name: {
                required,
                maxLength: maxLength(255),
            },
            email: {
                required,
                maxLength: maxLength(120),
                email,
            },
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(120),
            },
        },
    },
    computed: {
        ...mapGetters({
            createManagerLoading: "managers/createManagerLoading"
        }),

        firstNameErrors() {
            return this.computeErrors('payload', 'first_name', {
                required: "validationRequired",
                maxLength: {message: "validationMax", count: 255 }
            });
        },

        lastNameErrors() {
            return this.computeErrors('payload', 'last_name', {
                required: "validationRequired",
                maxLength: {message: "validationMax", count: 255 }
            });
        },

        passwordErrors() {
            return this.computeErrors('payload', 'password', {
                required: "validationRequired",
                minLength: {message: "validationMin", count: 8 },
                maxLength: {message: "validationMax", count: 120 }
            });
        },

        emailErrors() {
            return this.computeErrors('payload', 'email', {
                required: "validationRequired",
                email: "validation.email",
                maxLength: {message: "validationMax", count: 120}
            });
        },
    },
    methods: {
        ...mapActions({
            createManager: "managers/CREATE_MANAGER",
        }),
        ...mapMutations({
            showManagerCreatePopup: "managers/SHOW_MANAGER_CREATE_POPUP",
        }),

        registerManager() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.createManager(this.payload).then(response => {
                this.$toasted.success(response.message);
                this.showManagerCreatePopup(false);
            })
            .catch(error => {
                if (error.status === 422) {
                    this.$toasted.error(error.data.message);
                }
            });
        },
    }
}