import defaultImage from '@/assets/img/svg/default-image.svg';

export default {
  name: 'EmptyImage',
  props: {
    alt: {
      type: String,
      default: ''
    },
    isContain: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultImage
    };
  },
  computed: {},
  created() {},
  methods: {}
};
