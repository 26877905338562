import {$http} from '@/utils/https'
import type {ActionContext} from "vuex";
import type {RootState} from "@/store";
import type {Product} from "@/types/shop";
import type {IProductFaqPayload} from "@/store/modules/types/card";


interface State {
  product: Product | null;
  productLoading: boolean;
}

const state: State = {
  product: null,
  productLoading: false,
};

// getters
const getters = {
  product: (state: State) => state.product,
  productLoading: (state: State) => state.productLoading,
};

// actions
const actions = {
  GET_PRODUCT: async ({commit}: any, slug: any) => {
    commit('CHANGE_PRODUCT_LOADING', true);
    commit('RESET_PRODUCT');
    let url = `v1/catalog/product/${slug}`;
    try {
      const response = await $http.get(url);
      console.log('GET_PRODUCT', response.data);
      commit('SET_PRODUCT', response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_PRODUCT_LOADING', false);
    }
  },

  SEND_QUESTION: async ({commit}: ActionContext<State, RootState>, payload: IProductFaqPayload) => {
    try {
      await $http.post(`v1/products/faq`, payload);
    } catch (e) {
      throw e;
    }
  },
  GET_SAME_PRODUCTS: async ({commit}: ActionContext<State, RootState>, slug: string) => {
    try {
      const response = await $http.get<{ data: Array<Product> }>(`v1/products/${slug}/buy_with`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
};

// mutations
const mutations = {
  SET_PRODUCT(state: State, product: Product) {
    state.product = product;
  },
  RESET_PRODUCT(state: State) {
    state.product = null;
  },
  CHANGE_PRODUCT_LOADING(state: State, status: boolean) {
    state.productLoading = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
