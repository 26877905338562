import { mapGetters } from 'vuex';

export default {
  name: 'MainLoader',
  props: {},
  data() {
    return {};
  },
  components: {},
  validation() {},
  created() {},

  computed: {
    ...mapGetters({
      globalLoader: 'system/globalLoader'
    })
  },
  methods: {}
};
