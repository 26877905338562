import { $http } from '@/utils/https';
import { objectToQueryString } from '@/helpers/variables';

export type ISelectionFilter = {
  catalog_type?: string;
  sort_filter_type?: string;
  brands?: string;
  series?: string;
  categories?: string;
}
interface State {
  filters: Array<ISelectionFilter>
}

const state: State = {
  filters: [],
}

const getters = {
  filters: (state: State) => state.filters,
}

const actions = {
  GET_SELECTION_FILTERS: async ({commit}: any) => {
    let url = `v1/selection/get_filters`;
    try {
      const response = await $http.get(url);
      commit('SET_SELECTION_FILTERS', response.data.data);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  GET_SELECTION_PRODUCTS: async ({commit}: any, params: any) => {
    let url = `v1/selection/get_products`;
    if (params && Object.keys(params).length) {
      //---NOTE: rewrite per_page in init load products
      url = objectToQueryString(url, {per_page: 12, ...params});
    }

    try {
      const response = await $http.get(url);
      return response.data
    } catch (e) {
      throw e;
    }
  },
}

const mutations = {
  SET_SELECTION_FILTERS(state: any, data: Array<ISelectionFilter>) {
    state.filters = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
