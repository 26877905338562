import { mapGetters } from 'vuex';
import { OrderType } from '@/modules/buy-flow/pages/order';

export default {
  name: 'success-order',
  props: {
    payload: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      isDistributor: 'profile/isDistributor'
    })
  },
  created() {
    document.getElementById('html').classList.add('hide');
  },
  methods: {
    getText() {
      let text = this.$t('modal.successOrder.text');

      // text += this.$t('modal.successOrder.textWithSms');

      return text;
    }
  }
};
