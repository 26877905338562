import defaultImage from '@/assets/img/svg/default-image.svg';

/**
 *
 * @param {Product|BasketProduct} product
 * @param {ProductPrice|null} productPrice
 // * @return {{query: {value}, name: string, params: {query, slug}}}
 * @return {{query: {value}, name: string, params: {query, slug}}}
 */
export function routeToProduct(product, productPrice = null) {
  // let value = productPrice?.value || product.productPrices?.data[0]?.value;

  let { slug, brand } = product;
  let params = brand ? { slug, brand: brand.slug } : { slug };

  return {
    name: 'product',
    params
  };
}

/**
 *
 * @param {Product|BasketProduct} product
 * @param {ProductPrice|null} productPrice
 * @return {string|null}
 */
export function resolveProductImage(product, productPrice = null) {
  return (
    productPrice?.image ||
    product?.select_type?.image ||
    product?.productPrices?.data[0]?.image ||
    product.image ||
    defaultImage
  );
}

/**
 *
 * @param {Product|BasketProduct} product
 * @param {ProductPrice|null} productPrice
 * @return {string|null}
 */
export function resolveHoverProductImage(product, productPrice = null) {
  return (
    productPrice?.image2 || product?.select_type?.image2 || product?.productPrices?.data[0]?.image2 || product.image2
  );
}

/**
 * @param {ProductPrice} productPrice
 * @return {string}
 */
export function getProductPriceTitle(productPrice) {
  return productPrice.title || productPrice.value;
}

export const checkIfHasVideoUrl = (string) => {
  // Regex for video file URLs
  const videoFileRegex = /https?:\/\/(?:www\.)?[^\s]+(?:\.(mp4|mov|avi|mkv))/i;

  // Regex for video platform URLs
  const videoPlatformRegex =
    /https?:\/\/(?:www\.)?(youtube\.com|youtu\.be|vimeo\.com|tiktok\.com|dailymotion\.com|facebook\.com\/watch)/i;

  // Test both regexes
  return videoFileRegex.test(string) || videoPlatformRegex.test(string);
};

export const PRODUCT_SLIDER_ITEM_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video'
};

export const PRODUCT_TAB_TYPE = {
  EVERYTHING_ABOUT: 0,
  DESCRIPTION: 1,
  STRUCTURE: 2,
  RECOMMENDATION: 3,
  COMMENTS: 4,
  QUESTIONS: 5,
  ADDITIONAL: 6
};

export const PRODUCTS_VIEW_TYPE = {
  LIST: 'list',
  GRID: 'grid'
};

export const PRODUCTS_ORDER_KEY = {
  PRICE_DESC: 'price_desc',
  PRICE_ASC: 'price_asc',
  NEWEST: 'newest',
  WITH_PROMOTIONS: 'with_promotions',
  BESTSELLERS: 'bestsellers'
};
