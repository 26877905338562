export const registerBreadcrumbs = (manager) => {
  const vue = manager; // just alias for readability

  manager.register('home', (tail) => tail.push(vue.$t('home'), vue.$localize({ name: 'home' })));

  // pages
  manager.register('simple-page-item', (tail, { title }) => tail.parent('home').push(title));
  manager.register('ingredients', (tail) => tail.parent('home').push('Складові'));
  manager.register('news-list', (tail) => tail.parent('home').push('Aктуальні новини'));
  manager.register('video-list', (tail) => tail.parent('home').push('Відео матеріали'));
  manager.register('seminar-list', (tail) => tail.parent('home').push('Відео огляди та семінари'));

  manager.register('page', (tail, { page }) =>
    tail.parent('home').push(page.title, vue.$localize({ name: 'page', params: { slug: page.slug } }))
  );

  manager.register('page-for-type', (tail, { page, type }) => {
    let navigationItem = manager.resolveNavigationItemForPage(vue.$route.params.slugPage);

    if (type === 'new') {
      /*todo - use `configType` to search instead of `slug`*/
      navigationItem = manager.resolveNavigationItemForPage('scientific-news');
    }

    tail.parent('page', { page: navigationItem }).push(page.title);
  });

  manager.register('sub-page', (tail, { page, subPageItem }) => tail.parent('page', { page }).push(subPageItem.title));

  // but flow
  manager.register('buy-flow-basket', (tail) =>
    tail.parent('home').push(vue.$t('basket'), vue.$localize({ name: 'buy-flow-basket' }))
  );
  manager.register('buy-flow-order', (tail) => tail.parent('buy-flow-basket').push(vue.$t('completeOrder')));

  // catalog and products
  manager.register('ingredient-list', (tail, { category }) => {
    tail.parent('catalog').when(!!category.title, (tail) => {
      tail.push(
        category.title,
        vue.$localize({
          name: 'ingredient-list',
          params: { series: category.slug }
        })
      );
    });
  });
  manager.register('ingredient-list-paginated', (tail, { category }) => {
    tail.parent('catalog').when(!!category.title, (tail) => {
      tail.push(
        category.title,
        vue.$localize({
          name: 'ingredient-list',
          params: { series: category.slug }
        })
      );
    });
  });
  manager.register('product-card', (tail, { product }) => {
    tail
      .parent('catalog')
      .when(!!product.brand, (tail) => {
        tail.push(
          product.brand.title,
          vue.$localize({
            name: 'products',
            query: { brands: product.brand.name }
          })
        );
      })
      .when(!!product.brandSeries, (tail) => {
        tail.push(
          product.brandSeries.title,
          vue.$localize({
            name: 'products',
            query: { brands: product.brand.name, series: product.brandSeries.name }
          })
        );
      })
      .push(product.title /*vue.$localize(routeToProduct(product))*/);
  });

  // profile
  manager.register('profile', (tail) =>
    tail.parent('home').push(vue.$t('profile'), vue.$localize({ name: 'profile' }))
  );
};
