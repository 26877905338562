import { BLOG_NEWS_TYPE } from '@/helpers';
import EmptyImage from '@/components/@general/EmptyImage/index.vue';

export default {
  name: 'NewsCard',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: BLOG_NEWS_TYPE.NEWS
    }
  },
  data() {
    return {};
  },
  components: { EmptyImage },

  created() {},
  mounted() {},
  computed: {},

  methods: {}
};
