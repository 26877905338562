import { $http } from '@/utils/https';
import { objectToQueryString } from '@/helpers/variables';

interface State {}

const state: State = {};

const getters = {};

const actions = {
  GET_INGREDIENTS_LETTERS: async () => {
    let url = `v1/ingredients/letters`;
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  GET_INGREDIENTS: async ({ commit }: any, params: any) => {
    let url = `v1/ingredients`;
    if (params && Object.keys(params).length) {
      url = objectToQueryString(url, params);
    }
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  GET_INGREDIENT: async ({ commit }: any, slug: any) => {
    let url = `v1/ingredients/${slug}`;
    try {
      const response = await $http.get(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
