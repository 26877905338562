export const BLOG_NEWS_TYPE = {
  NEWS: 'news',
  INTERESTING: 'interesting'
};

export const BLOG_VIDEOS_TYPE = {
  VIDEOS: 'videos',
  MASTER_CLASS: 'master-classes'
};

export const BLOG_CONTENT_ASIDE_GROUP_TYPE = {
  FRESH: 'fresh',
  CATEGORIES: 'categories',
  ARCHIVES: 'archives'
};

export const BLOG_CONTENT_TYPE = {
  CONTENTS: 'contents',
  VIDEO: 'video',
  ARCHIVE: 'archive'
};

export const BLOG_BLOCK_TYPE = {
  BLOCK_WITH_VIDEO: 'block_with_video',
  BLOCK_WITH_IMAGE: 'block_with_image',
  DESCRIPTION: 'description',
  CONTENT_TITLE: 'content_title',
  TITLE_WITH_DESCRIPTION: 'title_with_description',
  TITLE_WITH_CENTER_DESCRIPTION: 'title_with_center_description',
  PHRASE: 'phrase'
};
