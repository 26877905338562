interface State {
  size: {
    w: number,
    h: number
  }
}

const state: State = {
  size: {
    w: window.innerWidth,
    h: window.innerHeight
  },
};

// getters
const getters = {
  size: (state: State) => state.size,

};

// actions
const actions = {
};

// mutations
const mutations = {
  SET_RESIZE(state: State, size: {w: number, h: number}) {
    state.size = size;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
