import { $http } from '@/utils/https';
import type {
  IBlogCommentPayload,
  IBlogUserCommentPayload,
  IProductCommentPayload
} from '@/store/modules/types/comments';

interface State {}

const state: State = {};

const getters = {};

const actions = {
  // eslint-disable-next-line no-unused-vars
  ADD_BLOG_COMMENT: async ({ commit }: any, payload: IBlogCommentPayload) => {
    let url = `v1/news/comment`;
    try {
      const response = await $http.post(url, payload);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  ADD_BLOG_USER_COMMENT: async ({ commit }: any, payload: IBlogUserCommentPayload) => {
    let url = `v1/news/comment-to-comment`;
    try {
      const response = await $http.post(url, payload);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  ADD_PRODUCT_COMMENT: async ({commit}: any, payload: IProductCommentPayload) => {
    try {
      let url = `v1/products/feedbacks`;
      const response = await $http.post(url, payload);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
