import { mapGetters, mapMutations } from 'vuex';
import Login from '@/components/popups/Login/index.vue';
import Registration from '@/components/popups/Registration/index.vue';
import reset from '@/components/popups/reset/index.vue';
import restore from '@/components/popups/restore/index.vue';
import addressesList from '@/components/popups/addresses-list/index.vue';
import instagramPost from '@/components/popups/instagram-post/index.vue';
import successRegister from '@/components/popups/success-register/index.vue';
import anotherCosmetolog from '@/components/popups/activate-another-cosmetolog/index.vue';
import fastBuy from '@/components/popups/fast-buy/index.vue';
import defaultPrices from '@/components/popups/default-prices/index.vue';
import formApplication from '@/components/popups/form-application/index.vue';
import paymentInfo from '@/components/popups/payment-info/index.vue';
import successRegisterAgent from '@/components/popups/successRegisterAgent/index.vue';
import seminarRegistration from '@/components/popups/seminar-registration/index.vue';
import successRegisterSeminar from '@/components/popups/succes-seminar-register/index.vue';
import successOrder from '@/components/popups/success-order/index.vue';
import basket from '@/components/popups/basket/index.vue';
import seminarDetails from '@/components/popups/seminar-details/index.vue';
import seminarInfo from '@/components/popups/seminar-info/index.vue';
import notifyWhenAvailable from '@/components/popups/notify-when-available/index.vue';
import SMSPopup from '@/components/popups/SMSPopup/index.vue';
import remainings from '@/components/popups/remainings/index.vue';
import metodistPopup from '@/components/popups/metodist-popup/index.vue';
import createManagerPopup from '@/components/popups/create-manager-popup/index.vue';
import videoModal from '@/components/popups/video-modal/index.vue';
import YoutubeModal from '@/components/popups/YoutubeModal/index.vue';

export default {
  name: 'Hidden',
  components: {
    Login,
    Registration,
    reset,
    restore,
    addressesList,
    instagramPost,
    successRegister,
    anotherCosmetolog,
    fastBuy,
    defaultPrices,
    formApplication,
    paymentInfo,
    successRegisterAgent,
    seminarRegistration,
    successRegisterSeminar,
    successOrder,
    basket,
    seminarDetails,
    notifyWhenAvailable,
    SMSPopup,
    remainings,
    seminarInfo,
    metodistPopup,
    createManagerPopup,
    videoModal,
    YoutubeModal
  },
  computed: {
    ...mapGetters({
      loginPopup: 'popups/loginPopup',
      registrationPopup: 'popups/registrationPopup',
      resetPopup: 'popups/resetPopup',
      restorePopup: 'popups/restorePopup',
      addressesListPopup: 'popups/addressesListPopup',
      instagramPopup: 'popups/instagramPopup',
      successRegisterPopup: 'popups/successRegisterPopup',
      anotherCosmetolog: 'popups/anotherCosmetolog',
      fastBuyPopup: 'popups/fastBuyPopup',
      showDefaultPrice: 'popups/showDefaultPrice',
      formApplicationPopup: 'popups/formApplicationPopup',
      paymentInfoPopup: 'popups/paymentInfoPopup',
      isRegisterAgent: 'popups/successRegisterAgent',
      showSeminarRegistration: 'seminars/showSeminarPopup',
      isShowSeminarSuccess: 'seminars/showSuccessPopup',
      isShowBasketPopup: 'basket/isShowBasketPopup',
      isShowRemaingsPopup: 'popups/showRemainingsPopup',
      showSuccessPaymentPopup: 'popups/showSuccessPaymentPopup',
      showSeminarInfoPopup: 'popups/showSeminarInfoPopup',
      seminarDetails: 'popups/seminarDetails',
      shoeNotifyWhenAvailable: 'popups/shoeNotifyWhenAvailable',
      showPhoneRegisterPopup: 'auth/showPhoneRegisterPopup',
      isProfessional: 'profile/isProfessional',
      metodistPopup: 'metodist/popupOpen',
      showManagerPopup: 'managers/showManagerPopup',
      seminarVideoModalStatus: 'popups/seminarVideoModalStatus',
      seminarVideoModalUrl: 'popups/seminarVideoModalUrl',
      youtubeVideoModalStatus: 'popups/youtubeVideoModalStatus',
      youtubeVideoModalId: 'popups/youtubeVideoModalId'
    })
  },
  methods: {
    ...mapMutations({
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
      changeRegistrationPopup: 'popups/CHANGE_REGISTRATION_POPUP',
      changeResetPopup: 'popups/CHANGE_RESET_POPUP',
      changeRestorePopup: 'popups/CHANGE_RESTORE_POPUP',
      changeAddressesListPopup: 'popups/CHANGE_ADDRESSES_LIST_POPUP',
      changeInstagramPostPopup: 'popups/CHANGE_INSTAGRAM_POPUP',
      changeSuccessRegisterPopup: 'popups/CHANGE_SUCCESS_REGISTER_POPUP',
      changeAnotherCosmetologPopup: `popups/CHANGE_COSMETOLOG_POPUP`,
      changeFastBuyPopup: `popups/CHANGE_FAST_BUY_POPUP`,
      changeDefaultPricesPopup: `popups/CHANGE_DEFAULT_PRICES`,
      changeApplicationFormPopup: `popups/CHANGE_APPLICATION_FORM_POPUP`,
      changePaymentInfoPopup: 'popups/CHANGE_PAYMENT_INFO_POPUP',
      changeRegisterAgent: 'popups/CHANGE_SHOW_SUCCESS_AGENT_REGISTER',
      showRemainingsPopup: 'popups/CHANGE_REMAININGS_POPUP',
      changeSeminarRegistration: 'seminars/SHOW_SEMINAR_POPUP',
      changeSeminarSuccess: 'seminars/SHOW_SUCCESS_SEMINAR',
      changeBasketPopup: 'basket/SHOW_BASKET_POPUP',
      changeSuccessPayment: 'popups/SHOW_SUCCESS_PAYMENT_POPUP',
      changeSeminarDetails: 'popups/SHOW_SEMINAR_DETAILS_POPUP',
      changeSeminarInfo: 'popups/SHOW_SEMINAR_INFO_POPUP',
      showNotifyPopup: 'popups/SHOW_NOTIFY_WHEN_AVAILABLE',
      showSmsRegisterPopup: 'auth/SHOW_PHONE_REGISTER_POPUP',
      closeMetodistPopup: 'metodist/CLOSE_METODIST_POPUP',
      showManagerCreatePopup: 'managers/SHOW_MANAGER_CREATE_POPUP',
      showSeminarVideoModal: 'popups/SHOW_SEMINAR_VIDEO_MODAL',
      showYoutubeVideoModal: 'popups/SHOW_YOUTUBE_VIDEO_MODAL'
    })
  }
};
