export const translation = {
  // 'validation.required': "Введіть обов'язкове поле",
  // 'validation.min_length': 'Мінімальна кількість символів - {size}',
  // 'validation.max_length': 'Максимальна кількість символів - {size}',
  // 'validation.email': 'Введіть коректний email',
  // 'validation.email_not_found': 'Email не знайдений found',
  // 'validation.passwords_match': 'Паролі не співпадають',
  // 'validation.wrong_sms': 'Неправильний СМС-код',
  // 'validation.wrong_input': 'Неправильно заповнене поле',
  // 'validation.empty_date': 'Дані відсутні',
  // 'validation.attribute': 'Виберіть атрибут',
  // 'validation.same_as': 'Поля не співпадають',
  // 'validation.numeric': 'Неправильне число',
  // 'validation.min_value': 'Мінімальне значення - {value}',
  // 'validation.max_value': 'Максимальне значення - {value}',
};
