import { mapActions, mapGetters, mapMutations } from 'vuex';

import { email, maxLength, minLength, required } from 'vuelidate/lib/validators';
import validation from '@/mixins/validation';
import IconEye from '@/components/icons/IconEye.vue';
import IconEyeClose from '@/components/icons/IconEyeClose.vue';
export default {
  name: 'Login',
  data() {
    return {
      payload: {
        email: this.$route.query.email ? this.$route.query.email : '',
        password: ''
      },
      validationErrors: {},
      showPassword: false,
      emailRules: {
        require: true,
        email: true
      },
      passwordRules: {
        minLength: 8,
        require: true
      },
      inputRefs: []
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      email: {
        required,
        maxLength: maxLength(120),
        email
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(120)
      }
    }
  },
  components: {
    IconEyeClose,
    IconEye
    // mainInput
  },
  computed: {
    ...mapGetters({
      loadingSingIn: 'auth/loadingSingIn',
      verifyCodeResponse: 'auth/verifyCodeResponse',
    }),
    passwordErrors() {
      return this.computeErrors('payload', 'password', {
        maxLength: { message: 'validationMax', count: 120 },
        required: 'validationRequired',
        minLength: { message: 'validationMin', count: 8 }
      });
    },
    emailErrors() {
      return this.computeErrors('payload', 'email', {
        maxLength: { message: 'validationMax', count: 120 },
        required: 'validationRequired',
        email: 'validation.email'
      });
    }
  },
  created() {
    document.getElementById('html').classList.add('hide');
  },
  methods: {
    ...mapMutations({
      showSmsRegisterPopup: 'auth/SHOW_PHONE_REGISTER_POPUP',
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
      changeRegistrationPopup: 'popups/CHANGE_REGISTRATION_POPUP',
      changeResetPopup: 'popups/CHANGE_RESET_POPUP'
    }),
    ...mapActions({
      login: 'auth/GET_TOKEN',
      fetchUser: 'profile/FETCH_USER_DATA',
      getFavorites: 'favorites/GET_FAVORITES_LIST',

    }),
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        await this.login(this.payload);
        await this.fetchUser();
        await this.getFavorites();

        this.$toasted.success(this.$t('successLogin'));
        this.changeLoginPopup(false);

      } catch (error) {
        const message = error.data?.message;
        if (message) {
          this.$toasted.error(message);
        }
      }
    },
    onChangeToSMSRegistration() {
      this.changeLoginPopup(false);
      if (this.verifyCodeResponse) {
        this.changeRegistrationPopup(true);
      } else {
        this.showSmsRegisterPopup(true);
      }

    },
    onChangeToReset() {
      this.changeLoginPopup(false);
      this.changeResetPopup(true);
    }
  }
};
