import {$http} from '@/utils/https';
import type {ActionContext} from "vuex";
import type {RootState} from "@/store";
import type {PaginatorData} from "@/types/api";
import type {Manager} from "@/types/managers";

interface State {
    showManagerPopup: boolean,
    createManagerLoading: boolean,
    managerList: Manager[],
    managerListLoading: boolean,
}

const state: State = {
    showManagerPopup: false,
    createManagerLoading: false,
    managerList: [],
    managerListLoading: false,
};

const getters = {
    showManagerPopup: (state: State) => state.showManagerPopup,
    createManagerLoading: (state: State) => state.createManagerLoading,
    managerList: (state: State) => state.managerList,
    managerListLoading: (state: State) => state.managerListLoading,
};

interface ICreateManagerPayload {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
}

interface IUpdateManagerPayload {
    id: number,
    first_name: string;
    last_name: string;
    email: string;
    password?: string | null;
}

interface IActivationsPayload {
    id: number;
    status: string;
}

const actions = {
    CREATE_MANAGER: async ({commit}: ActionContext<State, RootState>, payload: ICreateManagerPayload) => {
        commit('SET_CREATE_MANAGER_LOADING', true);
        try {
            const response = await $http.post<{ data: Manager }>(`v1/distributor/managers`, payload);
            commit('PUSH_MANAGER_LIST', [response.data.data]);
            return response.data;
        } catch (e) {
            throw e;
        } finally {
            commit('SET_CREATE_MANAGER_LOADING', false);
        }
    },
    GET_MANAGERS: async ({commit}: ActionContext<State, RootState>) => {
        commit('SET_MANAGER_LIST_LOADING', true);
        try {
            const response = await $http.get<PaginatorData<Manager[]>>(`v1/distributor/managers`);
            commit('SET_MANAGER_LIST', response.data.data);
            return response.data;
        } catch (e) {
            throw e;
        } finally {
            commit('SET_MANAGER_LIST_LOADING', false);
        }
    },
    GET_MANAGERS_NEXT_PAGE: async ({commit}: ActionContext<State, RootState>, page: number) => {
        try {
            const response = await $http.get<PaginatorData<Manager[]>>(`v1/distributor/managers?page=${page}`);
            commit('PUSH_MANAGER_LIST', response.data.data);
            return response.data;
        } catch (e) {
            throw e;
        }
    },
    CHANGE_MANAGER_ACTIVATIONS: async ({commit}: ActionContext<State, RootState>, payload: IActivationsPayload) => {
        try {
            const response = await $http.post<PaginatorData<Manager[]>>(`v1/distributor/managers/${payload.id}/${payload.status}`);
            return response;
        } catch (e) {
            throw e;
        }
    },
    DELETE_MANAGER: async ({commit}: ActionContext<State, RootState>, managerId: number) => {
        try {
            const response = await $http.delete<PaginatorData<Manager[]>>(`v1/distributor/managers/${managerId}`);
            commit('REMOVE_MANAGER_FROM_LIST', managerId);
            return response.data;
        } catch (e) {
            throw e;
        }
    },
    UPDATE_MANAGER: async ({commit}: ActionContext<State, RootState>, payload: IUpdateManagerPayload) => {
        try {
            const response = await $http.put<PaginatorData<Manager[]>>(`v1/distributor/managers/${payload.id}`, payload);
            return response.data;
        } catch (e) {
            throw e;
        }
    },
}

const mutations = {
    SHOW_MANAGER_CREATE_POPUP(state: State, payload: boolean) {
        state.showManagerPopup = payload
    },
    SET_CREATE_MANAGER_LOADING(state: State, payload: boolean) {
        state.createManagerLoading = payload
    },
    SET_MANAGER_LIST(state: State, payload: Manager[]) {
        state.managerList = payload;
    },
    PUSH_MANAGER_LIST(state: State, payload: Manager[]) {
        // @ts-ignore
        state.managerList.push(...payload);
    },
    REMOVE_MANAGER_FROM_LIST(state: State, id: number) {
        state.managerList = state.managerList.filter(user => user.id !== id);
    },
    SET_MANAGER_LIST_LOADING(state: State, payload: boolean) {
        state.managerListLoading = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
