import defaultImage from '@/assets/img/svg/default-image.svg';

export * from "./downloadFile";
export * from "./product";
export * from "./blog";
export * from "./catalog";
export * from "./comment";
export * from "./header";
export * from "./user-helpers";

export const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function getImage(image) {
    return image || defaultImage;
}