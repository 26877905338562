import { mapActions, mapGetters, mapMutations } from 'vuex';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { email, maxLength, minLength, required, sameAs } from 'vuelidate/lib/validators';
import validation from '@/mixins/validation';
import { REGISTER_TYPE } from '@/helpers';
import IconEye from '@/components/icons/IconEye.vue';
import IconEyeClose from '@/components/icons/IconEyeClose.vue';
import IconClose from '@/components/icons/IconClose.vue';

export default {
  name: 'Registration',
  data() {
    return {
      REGISTER_TYPE,
      isAgree: false,
      payload: {
        register_mode: REGISTER_TYPE.REGULAR,
        phone_country: '',
        email: '',
        password: '',
        password_confirmation: '',
        name: '',
        second_name: '',
        phone: '',
        work: '',
      },
      validationErrors: {},
      showPassword: false,
      rule: {
        require: true
      }
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      phone: {
        required
      },
      email: {
        required,
        maxLength: maxLength(120),
        email
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(120),
        sameAsPassword: sameAs('password')
      },
      password_confirmation: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(120),
        sameAsPassword: sameAs('password')
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(120)
      },
      second_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(120)
      }
    }
  },
  components: {
    IconClose,
    IconEyeClose,
    IconEye
    // mainInput
  },
  watch: {
    isAgree() {
      this.$refs.agreeError.innerHTML = !this.isAgree ? this.$t('modal.registration.agreeText') : '';
    },
    registrationMode: function () {
      // hide all popups
      this.changeRegistrationPopup(false);
      // if no verification code exists, then we show the sms popup
      if (!this.verifyCodeResponse) {
        this.showSmsRegisterPopup(true);
        return;
      }

      // otherwise, we show the registration popup for specific mode
      this.changeRegistrationPopup(true);
    }
  },
  computed: {
    ...mapGetters({
      loadingRegistration: 'auth/loadingRegistration',
      verifyCodeResponse: 'auth/verifyCodeResponse'
    }),
    passwordErrors() {
      return this.computeErrors('payload', 'password', {
        maxLength: { message: 'validationMax', count: 120 },
        required: 'validationRequired',
        minLength: { message: 'validationMin', count: 8 }
      });
    },
    passwordConfirmationErrors() {
      return this.computeErrors('payload', 'password_confirmation', {
        maxLength: { message: 'validationMax', count: 120 },
        required: 'validationRequired',
        sameAsPassword: 'validationSameAs',
        minLength: { message: 'validationMin', count: 8 }
      });
    },
    emailErrors() {
      return this.computeErrors('payload', 'email', {
        maxLength: { message: 'validationMax', count: 120 },
        required: 'validationRequired',
        email: 'validation.email'
      });
    },
    nameErrors() {
      return this.computeErrors('payload', 'name', {
        required: 'validationRequired',
        minLength: { message: 'validationMin', count: 3 },
        maxLength: { message: 'validationMax', count: 120 }
      });
    },
    secondNameErrors() {
      return this.computeErrors('payload', 'second_name', {
        required: 'validationRequired',
        minLength: { message: 'validationMin', count: 3 },
        maxLength: { message: 'validationMax', count: 120 }
      });
    }
  },
  created() {
    this.payload.phone = this.verifyCodeResponse?.phone;

    document.getElementById('html').classList.add('hide');
  },
  methods: {
    ...mapMutations({
      changeRegistrationPopup: 'popups/CHANGE_REGISTRATION_POPUP',
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
      changeSuccessRegisterPopup: 'popups/CHANGE_SUCCESS_REGISTER_POPUP',
      showSmsRegisterPopup: 'auth/SHOW_PHONE_REGISTER_POPUP'
    }),
    ...mapActions({
      registrations: 'auth/REGISTRATION',
      fetchUser: 'profile/FETCH_USER_DATA'
    }),
    onChangeToLogin() {
      this.changeRegistrationPopup(false);
      this.changeLoginPopup(true);
    },
    async onSubmit() {
      this.$refs.agreeError.innerHTML = !this.isAgree ? this.$t('modal.registration.agreeText'): '';

      this.$v.$touch();

      if (this.$v.$invalid || !this.isAgree) {
        return;
      }

      const parsedNumber = parsePhoneNumberFromString(this.payload.phone);

      this.payload.phone_country = parsedNumber ? parsedNumber.country : '';
      this.payload.confirm_token = this.verifyCodeResponse.confirmToken;

      try {
        await this.registrations(this.payload);

        this.fetchUser().then(() => {
          this.changeRegistrationPopup(false);
          this.$router
            .push({ name: 'profile' })
            .then(() => {})
            .catch(() => {});
        });
      } catch (error) {
        this._handleRegistrationErrors(error);
      }
    },
    _handleRegistrationErrors(error) {
      if (!error?.data?.errors) {
        return;
      }

      const processErrors = ['email', 'phone', 'assignTo'];
      for (const processErrorsKey of processErrors) {
        const errors = error.data.errors[processErrorsKey];
        if (!errors || !errors[0]) {
          continue;
        }

        this.$toasted.error(errors[0]);
      }
    }
  }
};
