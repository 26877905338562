// import validation from '@/mixins/validation.js'
// import mainInput from '../../atoms/main-input/index.vue'
import { mapActions, mapGetters, mapMutations } from "vuex";
import { email, maxLength, required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
    name: "reset",
    data() {
        return {
            payload: {
                email: "",
            },
            wrongEmail: "",
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            email: {
                required,
                maxLength: maxLength(120),
                email,
            },
        },
    },

    components: {
        // mainInput
    },
    // mixins: [validation],
    watch: {
        "payload.email": function() {
            this.wrongEmail = "";
        },
    },
    computed: {
        ...mapGetters({
            isLoading: "auth/isLoading",
        }),
        emailErrors() {
            return this.computeErrors('payload', 'email', {
                maxLength: {message: "validationMax", count: 120 },
                required: "validationRequired",
                email: "validation.email",
            });
        },
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    methods: {
        ...mapMutations({
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeResetPopup: "popups/CHANGE_RESET_POPUP",
        }),
        ...mapActions({
            resetPassword: "auth/RESET_PASSWORD",
        }),
        sendPassword() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.resetPassword(this.payload)
                .then(() => {
                    this.$toasted.success(this.$t("messageResetPassword"));
                    this.changeResetPopup(false);
                })
                .catch(error => {
                    this.$toasted.error(error.data.message);

                    this.wrongEmail = error.data.errors?.email;
                });
        },
    },
};
