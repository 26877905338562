import {mapGetters} from "vuex";
import {storage} from "@/utils/storage";

export default {
  name: "Footer",
  data() {
    return {
      showIosAppPopup: false,
      showAndroidAppPopup: false,
      showOpSystemPopUp: false,
    };
  },
  components: {
  },
  computed: {
    ...mapGetters({
      contacts: "setting/variables",
      headerMenu: "setting/headerMenu",
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
    }),
  },
  mounted() {
    this.getMobileOperatingSystem();
  },
  methods: {
    /**
     * @param {keyof GeneralContacts|string} field
     * @return {string}
     */
    closeAppPopup() {
      this.showOpSystemPopUp = false;
    },
    getMobileOperatingSystem() {
      if (!storage.getItem("userMobileOperatingSystem")) {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let userMobileOperatingSystem;

        if (/android|Android/i.test(userAgent)) {
          userMobileOperatingSystem = "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          userMobileOperatingSystem = "iOS";
        }
        if (!(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) && !/android|Android/i.test(userAgent)) {
          return (userMobileOperatingSystem = undefined);
        }
        if (userMobileOperatingSystem) {
          if (userMobileOperatingSystem === "iOS") {
            storage.setItem("userMobileOperatingSystem", userMobileOperatingSystem);
            setTimeout(() => {
              this.showOpSystemPopUp = true;
              return (this.showIosAppPopup = true);
            }, 3000);
          }
          if (userMobileOperatingSystem === "Android") {
            setTimeout(() => {
              this.showOpSystemPopUp = true;
              return (this.showAndroidAppPopup = true);
            }, 3000);
          }
        }
      }
    },
    getContact(field) {
      return this.contacts?.contacts[field] || "";
    },
  },
};
