import HeaderNavDropdown from '@/components/@general/Header/HeaderNav/HeaderNavDropdown/index.vue'

export default {
  name: 'HeaderNavDropdown',
  components: {
    HeaderNavDropdown
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};
