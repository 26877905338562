import Vue from 'vue';
import VueI18nManager from 'vue-i18n-manager';
import store from '@/store';
import router from '@/router';
import { translation } from '@/helpers/translation';

const decorateStringToObject = (undecorated) => {
  const result = {};

  for (const [key, value] of Object.entries(undecorated)) {
    const keys = key.split('.');
    keys.reduce((acc, curr, index) => {
      if (index === keys.length - 1) {
        acc[curr] = value;
      } else {
        acc[curr] = acc[curr] || {};
      }
      return acc[curr];
    }, result);
  }
  return result;
}

const proxy = {
  getTranslation: async () => {
    try {
      const response = await store.dispatch(`setting/GET_TRANSLATIONS`);
      Vue.use(response, decorateStringToObject(translation));
      return decorateStringToObject(response);
    } catch (e) {
      console.error(e);
      return decorateStringToObject(translation);
    }
  }
};

Vue.use(VueI18nManager, {
  store,
  router,
  proxy,
  config: {
    defaultCode: 'ru-RU',
    languages: [
      {
        name: 'Ukranian',
        title: 'UA',
        key: 'uk',
        code: 'ua-UA',
        urlPrefix: 'ua',
        translationKey: 'ua'
      },
      {
        name: 'Russian',
        title: 'Ru',
        key: 'ru',
        code: 'ru-RU',
        urlPrefix: null,
        translationKey: 'ru'
      }
    ],
    translations: proxy
  }
});
