import HeaderNav from '@/components/@general/Header/HeaderNav/index.vue';

export default {
  name: 'HeaderSidebar',
  components: { HeaderNav },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};
