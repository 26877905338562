import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

let routes = [
  //---catalog
  {
    path: '/:lang?/katalog/page/:page(\\d+)/',
    name: 'products-paginated',
    component: () => import('@/modules/Catalog/index.vue')
  },
  {
    path: '/:lang?/katalog/:brand?/:slug/',
    name: 'product',
    component: () => import('@/modules/Product/index.vue')
  },
  {
    path: '/:lang?/katalog/',
    name: 'products',
    component: () => import('@/modules/Catalog/index.vue')
  },
  {
    path: '/:lang?/novinki/page/:page(\\d+)/',
    name: 'products-newest-paginated',
    component: () => import('@/modules/CatalogType/index.vue')
  },
  {
    path: '/:lang?/novinki/',
    name: 'products-newest',
    component: () => import('@/modules/CatalogType/index.vue')
  },
  {
    path: '/:lang?/top-prodazh/page/:page(\\d+)/',
    name: 'products-bestsellers-paginated',
    component: () => import('@/modules/CatalogType/index.vue')
  },
  {
    path: '/:lang?/top-prodazh/',
    name: 'products-bestsellers',
    component: () => import('@/modules/CatalogType/index.vue')
  },
  //---END catalog
  //---series
  {
    path: '/:lang?/product-category/:brand?/:series/page/:page(\\d+)/',
    name: 'series-paginated',
    component: () => import('@/modules/Series/index.vue')
  },
  {
    path: '/:lang?/product-category/:brand?/:series/',
    name: 'series',
    component: () => import('@/modules/Series/index.vue')
  },
  //---END series
  //---ingredients
  {
    path: '/:lang?/ingredienty/',
    name: 'ingredients',
    component: () => import('@/modules/Ingredients/index.vue')
  },
  {
    path: '/:lang?/ingredients/:slug/page/:page(\\d+)/',
    name: 'ingredient-paginated',
    component: () => import('@/modules/Ingredient/index.vue')
  },
  {
    path: '/:lang?/ingredients/:slug/',
    name: 'ingredient',
    component: () => import('@/modules/Ingredient/index.vue')
  },
  //---END ingredients
  {
    path: '/:lang?/podbor-kosmetiki/',
    name: 'selection',
    component: () => import('@/modules/Selection/index.vue')
  },
  //---blog
  {
    path: '/:lang?/news/page/:page(\\d+)/',
    name: 'news-paginated',
    component: () => import('@/modules/News/index.vue')
  },
  {
    path: '/:lang?/news/:slug/',
    name: 'news-single',
    component: () => import('@/modules/Article/index.vue')
  },
  {
    path: '/:lang?/news/',
    name: 'news',
    component: () => import('@/modules/News/index.vue')
  },
  {
    path: '/:lang?/interesnye-stati/page/:page(\\d+)/',
    name: 'interesting-paginated',
    component: () => import('@/modules/News/index.vue')
  },
  {
    path: '/:lang?/interesnye-stati/:slug/',
    name: 'interesting-single',
    component: () => import('@/modules/Article/index.vue')
  },
  {
    path: '/:lang?/interesnye-stati/',
    name: 'interesting',
    component: () => import('@/modules/News/index.vue')
  },
  {
    path: '/:lang?/video-materialy/page/:page(\\d+)/',
    name: 'videos-paginated',
    component: () => import('@/modules/Videos/index.vue')
  },
  {
    path: '/:lang?/video/:slug/',
    name: 'videos-single',
    component: () => import('@/modules/Video/index.vue')
  },
  {
    path: '/:lang?/video-materialy/',
    name: 'videos',
    component: () => import('@/modules/Videos/index.vue')
  },
  {
    path: '/:lang?/video-master-klassy/page/:page(\\d+)/',
    name: 'master-classes-paginated',
    component: () => import('@/modules/Videos/index.vue')
  },
  {
    path: '/:lang?/video-master-klassy/:slug/',
    name: 'master-classes-single',
    component: () => import('@/modules/Video/index.vue')
  },
  {
    path: '/:lang?/video-master-klassy/',
    name: 'master-classes',
    component: () => import('@/modules/Videos/index.vue')
  },
  {
    path: '/:lang?/:year(\\d{4})/:month(0[1-9]|1[0-2])?/page/:page(\\d+)/',
    name: 'archive-paginated',
    component: () => import('@/modules/Archives/index.vue')
  },
  {
    path: '/:lang?/:year(\\d{4})/:month(0[1-9]|1[0-2])?/',
    name: 'archive',
    component: () => import('@/modules/Archives/index.vue')
  },
  //---END blog

  {
    path: '/:lang?/training-center/',
    name: 'training',
    component: () => import('@/modules/teaching/training-center/index.vue')
  },
  {
    path: '/:lang?/training-center/:slug/',
    name: 'seminars',
    component: () => import('@/modules/teaching/seminars/index.vue')
  },
  {
    path: '/:lang?/forum/',
    name: 'forum',
    component: () => import('@/modules/teaching/forum/index.vue')
  },

  {
    path: '/:lang?/server-maintenance/',
    name: 'server-maintenance',
    component: () => import('../modules/system/server-maintenance/index.vue')
  },
  {
    path: '/:lang?/server-error/',
    name: 'server-error',
    component: () => import('../modules/system/server-error/index.vue')
  },
  {
    path: '/:lang?/results/',
    name: 'result',
    component: () => import('../modules/SearchCatalog/index.vue')
  },
  {
    path: '/:lang?/buy-flow/',
    name: 'buy-flow',
    redirect: '/:lang?/buy-flow/basket',
    component: () => import('../modules/buy-flow/index.vue'),
    children: [
      {
        path: 'basket/',
        name: 'buy-flow-basket',
        component: () => import('../modules/buy-flow/pages/basket/index.vue'),
        meta: {
          title: 'Корзина'
        }
      },
      {
        path: 'order/',
        name: 'buy-flow-order',
        component: () => import('../modules/buy-flow/pages/order/index.vue'),
        meta: {
          title: 'Оформлення замовлення'
        }
      }
    ]
  },
  // {
  //   path: '/:lang?/agent-register',
  //   name: 'agent-register',
  //   component: () => import('../modules/agent-registrations/index.vue')
  // },

  //--------------------------------------pages--------------------------------------
  {
    path: '/:lang?/pages/:slug/',
    name: 'page',
    component: () => import('../modules/pages/page-main/index.vue')
  },
  {
    path: '/:lang?/pages/:slugPage/:slugIngredient/',
    name: 'sub-page',
    component: () => import('../modules/pages/sub-page/index.vue')
  },
  {
    path: '/:lang?/contact/',
    name: 'contacts',
    component: () => import('../modules/Contacts/index.vue')
  },
  {
    path: '/:lang?/about-us/',
    name: 'about-us',
    component: () => import('../modules/About/index.vue')
  },
  //--------------------------------------pages--------------------------------------

  {
    path: '/:lang?/faq/',
    name: 'faq',
    component: () => import('../modules/pages/faq/index.vue')
  },
  {
    path: '/:lang?/delivery/',
    name: 'delivery',
    component: () => import('../modules/pages/delivery-payment/index.vue')
  },
  {
    path: '/:lang?/profile/',
    redirect: '/:lang?/profile/user-info/',
    component: () => import('../modules/Profile/index.vue'),
    name: 'profile',
    beforeEnter: isAuthenticated,
    children: [
      {
        path: 'user-info/',
        name: 'profile-user-info',
        component: () => import('../modules/Profile/pages/user-info/index.vue')
      },
      {
        path: 'user-professional/',
        name: 'profile-user-professional',
        component: () => import('../modules/Profile/pages/user-professional/index.vue')
      },
      {
        path: 'user-current-orders/',
        name: 'profile-user-current-orders',
        component: () => import('../modules/Profile/pages/current-orders/index.vue')
      },
      {
        path: 'user-history-orders/',
        name: 'profile-user-history-orders',
        component: () => import('../modules/Profile/pages/history-orders/index.vue')
      },
      {
        path: 'managers/',
        name: 'managers',
        meta: { title: 'менеджери' },
        component: () => import('../modules/Profile/pages/managers/index.vue')
      },
      {
        path: 'platform/',
        name: 'platform',
        component: () => import('@/modules/Profile/pages/platform/index.vue'),
        redirect: '/:lang?/profile/platform/seminars',
        children: [
          {
            path: 'seminars/',
            name: 'platform-seminars',
            component: () => import('@/modules/Profile/pages/platform/pages/seminars/index.vue')
          },
          {
            path: 'webinar/:id/',
            name: 'webinar',
            component: () => import('@/modules/Profile/pages/platform/pages/webinars/index.vue')
          },
          {
            path: 'webinar/:id/quiz/',
            name: 'quiz',
            component: () => import('@/modules/Profile/pages/platform/pages/test/index.vue')
          },
          {
            path: 'webinar/:id/quiz-result/',
            name: 'quiz-result',
            component: () => import('@/modules/Profile/pages/platform/pages/test-result/index.vue')
          }
        ]
      },
      {
        path: 'user-favorites/',
        name: 'profile-user-favorites',
        component: () => import('../modules/Profile/pages/favorites/index.vue')
      },
      {
        path: 'user-professional-club/',
        name: 'user-professional-club',
        redirect: 'user-professional-club/clients-list',

        component: () => import('../modules/Profile/pages/professional-club/index.vue'),
        children: [
          {
            path: 'clients-list/',
            name: 'profile-clients-list',
            component: () => import('../modules/Profile/pages/professional-club/pages/clients-list/index.vue')
          },
          {
            path: 'client-history/:id/',
            name: 'profile-client-history',
            component: () => import('../modules/Profile/pages/professional-club/pages/client-history/index.vue')
          }
        ]
      },
      {
        path: 'distributor-orders/',
        name: 'distributor-orders',
        redirect: 'distributor-orders/moving',

        component: () => import('../modules/Profile/pages/distributor-orders/index.vue'),
        children: [
          {
            path: 'moving/',
            name: 'moving',
            meta: { title: 'Переміщення на склад' },
            component: () =>
              import('../modules/Profile/pages/distributor-orders/pages/distributor-orders-moving/index.vue')
          },
          {
            path: 'invoice/',
            name: 'invoice',
            meta: { title: 'Видаткові накладні' },

            component: () =>
              import('../modules/Profile/pages/distributor-orders/pages/distributor-orders-invoice/index.vue')
          }
        ]
      },
      {
        path: 'distributor-storage/',
        name: 'distributor-storage',
        redirect: 'distributor-storage/leftovers',

        component: () => import('../modules/Profile/pages/distributor-storage/index.vue'),
        children: [
          {
            path: 'leftovers/',
            name: 'leftovers',

            component: () =>
              import('../modules/Profile/pages/distributor-storage/pages/distributor-storage-leftovers/index.vue')
          },
          {
            path: 'sales/',
            name: 'sales',
            meta: { title: 'Продажі зі складу' },

            component: () =>
              import('../modules/Profile/pages/distributor-storage/pages/distributor-storage-sales/index.vue')
          },
          {
            path: 'report/',
            name: 'report',

            component: () =>
              import('../modules/Profile/pages/distributor-storage/pages/distributor-storage-report/index.vue')
          }
        ]
      }
    ]
  },
  //---tag
  {
    path: '/:lang?/:category/:tag/page/:page(\\d+)/',
    name: 'tag-paginated',
    component: () => import('@/modules/CatalogTag/index.vue')
  },
  {
    path: '/:lang?/:category/:tag/',
    name: 'tag',
    component: () => import('@/modules/CatalogTag/index.vue')
  },
  //---END tags
  {
    path: '/:lang?/',
    name: 'home',
    component: () => import('../modules/Home/index.vue')
  },
  {
    path: '*',
    name: 'page-not-found',
    component: () => import('../modules/system/page-not-found/index.vue'),
    redirect: (to) => {
      if (!to.path.endsWith('/')) {
        return `${to.path}/`;
      }
      return to.path;
    }
  }
];

const addStrictOptionToRoutes = (routes) => {
  return routes.map((route) => {
    if (route.path !== '*') {
      if (!route.pathToRegexpOptions) {
        route.pathToRegexpOptions = { strict: true };
      }

      if (route.children && Array.isArray(route.children)) {
        route.children = addStrictOptionToRoutes(route.children);
      }
    }
    return route;
  });
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: addStrictOptionToRoutes(routes),
  scrollBehavior(to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition;
      } else {
        el.scrollTop = 0;
        return { x: 0, y: 0 };
      }
    }
  }
});

// router.beforeEach((to, from, next) => {
//   if (!to.path.endsWith('/')) {
//     next({ path: `${to.path}/`, query: to.query, hash: to.hash });
//   } else {
//     next();
//   }
// });

export default router;
