import { $http } from '@/utils/https';
import type { InstagramPost } from '@/types/site';
import type { Product } from '@/types/shop';

interface State {}

const state: State = {};

const getters = {};

const actions = {
  GET_HOME_BANNERS: async () => {
    let url = `v1/home/banners`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_PAGE: async () => {
    let url = `v1/home/page`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_SERIES: async () => {
    let url = `v1/home/series`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_NEW: async () => {
    let url = `v1/home/new`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_TOP: async () => {
    let url = `v1/home/top`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_NEWS: async () => {
    let url = `v1/home/news`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_INSTAGRAM_POSTS: async () => {
    let url = `v1/home/instagram_posts`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  //---#todo legacy, ask manager
  INSTAGRAM_POSTS: async () => {
    let url = `v1/instagram_posts`;
    try {
      const response = await $http.get(url);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
