import { mapGetters } from 'vuex';
import { registerBreadcrumbs } from './registerBreadcrumbs';

export default {
  name: 'Breadcrumbs',
  data() {
    return {
      breadcrumbs: [],
      callbacks: {}
    };
  },
  props: {
    name: {
      type: String,
      default: null
    },
    params: {
      type: Object | Array,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      translations: 'setting/translations',
      headerMenu: 'setting/headerMenu'
    }),
    breadcrumbsList() {
      return this.name ? this.generate(this.name, this.params) : [];
    }
  },
  created() {
    registerBreadcrumbs(this);
  },
  methods: {
    execCallback(name, params = {}) {
      if (!this.callbacks[name]) {
        throw new Error(`Breadcrumb not found with name "${name}"`);
      }

      this.callbacks[name](this, params);
    },

    generate(name, params = {}) {
      this.breadcrumbs = [];

      if (!name) {
        name = this.$route.name;
      }

      this.execCallback(name, params);

      return this.breadcrumbs;
    },

    parent(name, params = {}) {
      this.execCallback(name, params);

      return this;
    },

    push(title, route = null) {
      this.breadcrumbs.push({ title, route });

      return this;
    },

    when(condition, callback) {
      if (!condition) {
        return this;
      }

      callback(this);

      return this;
    },

    register(name, callback) {
      this.callbacks[name] = callback;

      return this;
    },

    resolveNavigationItemForPage(slug) {
      for (let i in this.headerMenu) {
        let item = this.headerMenu[i];
        if (item.slug === slug) return item;

        for (let j in item.items) {
          let childItem = item.items[j];
          if (childItem.slug === slug) return childItem;

          for (let k in childItem.items) {
            let subChild = childItem.items[k];
            if (subChild.slug === slug) return item;
          }
        }
      }

      return null;
    }
  }
};
