import IconFacebook from '@/components/icons/IconFacebook.vue';
import IconInstagram from '@/components/icons/IconInstagram.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderTop',
  components: { IconInstagram, IconFacebook },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(['languages', 'currentLanguage']),
    ...mapGetters({
      contacts: 'setting/variables',
    })
  },
  methods: {
    getContact(field) {
      return this.contacts?.contacts[field] || '';
    },
    changeLanguage(code) {
      this.$setLanguage(code).then(() => {
        // setTimeout(() => {
          window.location.reload();
        // }, 900);
      });
    },
  }
};
