import Vue from 'vue';
import type { ModuleTree } from 'vuex';
import Vuex from 'vuex';

Vue.use(Vuex);

interface ModuleState {}

import documents from './modules/documents';
import home from './modules/home';
import remainings from './modules/remainings';
import setting from './modules/setting';
import seminars from './modules/seminars';
import courses from './modules/courses';
import product from './modules/product';
import products from './modules/products';
import profile from './modules/profile';
import system from './modules/system';
import favorites from './modules/favorites';
import popups from './modules/popups';
import order from './modules/order';
import basket from './modules/basket';
import metodist from './modules/metodist';
import auth from './modules/auth';
import search from './modules/search';
import pages from './modules/pages';
import managers from './modules/managers';
import ingredients from './modules/igrendients';
import catalog from './modules/catalog';
import app from './modules/app'; //---#todo relocate to here methods from system, setting stores
import selection from './modules/selection';
import blog from './modules/blog';
import comments from './modules/comments';
import meta from './modules/meta';

const modules: ModuleTree<ModuleState> = {
  documents,
  home,
  remainings,
  setting,
  seminars,
  courses,
  product,
  products,
  profile,
  system,
  favorites,
  popups,
  order,
  basket,
  metodist,
  auth,
  search,
  pages,
  managers,
  ingredients,
  catalog,
  app,
  selection,
  blog,
  comments,
  meta
};

export interface RootState {}

const store = new Vuex.Store({
  modules
});

// @ts-ignore
export default store;
