export const COMMENT_TYPE = {
  COMMENTS: 'comments',
  FEEDBACKS: 'feedbacks'
};

export const countComments = (comments, type) => {
  return comments.reduce((acc, item) => {
    let child = type === COMMENT_TYPE.COMMENTS ? item.comments : item.child;
    let childArray = child.data && child.data.length ? child.data : [];
    let childCounter = childArray.length ? countComments(childArray, type) : 0;
    return acc + 1 + childCounter;
  }, 0);
};
