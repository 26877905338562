export default {
  name: 'HeaderMessage',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};
